// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks and Redux
import { useAppSelector } from '../../../redux/hooks';

// Components
import EditECData from './EditECData';

// MUI Components
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import dayjs from "dayjs";

import {
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid';

import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  enUS
} from '@mui/x-data-grid-pro';

// Utils
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

// Styles
import theme from '../../../styles/theme';

// Types
import { thTHGrid } from '../../../@types/DataGridLocaleText';
import RoleType from '../../../@types/RoleType';
import {
  Job,
  Site,
  ECData as ECDataType,
  CompanyInter
} from '../../../@types';
// Services
import { getAllSites } from '../../../services/SiteService';
import { getAllJobs } from '../../../services/JobService';
import { editECExportedStatus, getECData, getECExport } from '../../../services/ECService';
// Services
import {
  getAllActiveCompany,
  getAllActiveWorkSchedule
} from "../../../services/MasterDataService";
// import { getRoleById } from '../../../services/RoleService';
import jwtDecode from "jwt-decode";

const DataToEC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  const userRoleType = useAppSelector(state => state.user.roletype);
  const [sitesData, setSitesData] = useState<Site[]>([]);
  const [jobsData, setJobsData] = useState<Job[]>([]);
  const [sitesLoading, setSitesLoading] = useState<boolean>(true);
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);
  const [selectedSites, setSelectedSites] = useState<Site[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<Job[]>([]);
  const [workScheduleList, setWorkScheduleList] = useState<{ external_code: string, label: string }[]>([]);
  const [filterECDataData, setFilterECDataData] = useState<ECDataType[]>([]);
  const [ECRowsSelected, setECRowsSelected] = useState<GridRowSelectionModel>([]);

  const [editClicked, setEditClicked] = useState<boolean>(false);
  const [clickedId, setClickedId] = useState<string>('');
  const [companyList, setCompanyList] = useState<{ company_code: string, company_name: string }[]>([]);
  const [loadingCompanyList, setLoadingCompanyList] = useState<boolean>(true);

  const allSuccessLoading = !sitesLoading && !jobsLoading && !loadingCompanyList;

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await getAllSites();

        if (response.data?.success) {
          const sitesData = response.data?.sites;

          setSitesData(sitesData);
          setTimeout(() => {
            setSitesLoading(false)
          }, 500);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    const fetchJobs = async () => {
      try {
        const response = await getAllJobs();

        if (response.data?.success) {
          const jobsData = response.data?.jobs;

          setJobsData(jobsData);
          setTimeout(() => {
            setJobsLoading(false)
          }, 500);
        }
      } catch (error) {
        navigate('/servererror', { replace: true });
      }
    };

    const fetchAllActiveCompany = async () => {
      try {
          const response = await getAllActiveCompany();
          if (response.data?.success) {

              setCompanyList(response.data.companies);
          }
      } catch (error) {
          console.log(error);
          navigate('/servererror', { replace: true });
      } finally {
          setLoadingCompanyList(false);
      }
  }
  const fetchAllActiveWorkSchedule = async () => {
    try {
        const response = await getAllActiveWorkSchedule();

        if (response.data?.success) {
            setWorkScheduleList(response.data.workSchedules);
        }
    } catch (error) {
        console.log(error);
        navigate('/servererror', { replace: true });
    }
}
    fetchSites();
    fetchJobs();
    fetchAllActiveCompany();
    fetchAllActiveWorkSchedule();
  }, [navigate]);


  useEffect(() => {
    try {
      sitesData.sort((a, b) => a.site_id.localeCompare(b.site_id))
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  }, [sitesData, navigate]);

  useEffect(() => {
    try {
      jobsData.sort((a, b) => a.job_name.localeCompare(b.job_name))
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  }, [jobsData, navigate]);

  useEffect(() => {
    try {
      const fetchAllECData = async () => {
        try {


        const roleUserId = localStorage.getItem('access_token') && localStorage.getItem('access_token') ? 
        jwtDecode<any>(localStorage.getItem('access_token')!).Sites 
        : ''

          const filterData = {
            site_ids: roleUserId, //selectedSites.map(site => site.site_id),
            job_ids: selectedJobs.map(job => job.id),
            ec_ids: ECRowsSelected.map((row: any) => row)
          }
        const response = await getECData(filterData);

          if (response.data?.success) {
            const ecData = response.data?.ECData.data;
            setFilterECDataData(ecData);
          }
        } catch (error) {
          navigate('/servererror', { replace: true });
        }
      }

      fetchAllECData();
    } catch (error) {
      navigate('/servererror', { replace: true });
    }
  }, [navigate]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

   function mapNameCompany(companyCode: string) {
      const data: CompanyInter =  companyList.find((item:CompanyInter) =>  companyCode === item.company_code )!;
      const newCompanyFilter: string = data ? `${data?.company_code} - ${data?.company_name}`: companyCode
      return (newCompanyFilter)
    }
  const mapSites = (site_id: string) => {
      const data: any =  sitesData.find((item:any) =>  site_id === item.site_id )!;
      const siteText: string = data ? `${data?.site_name}[${data?.site_id}]`: site_id
      return (siteText)
  }
  const mapWorkSchedule = (code: string) => {
    const data: any =  workScheduleList.find((item:any) =>  code === item.external_code )!;
    const workScheduleText: string = data ? data.label : code
    return (workScheduleText)
  }

  const columns: GridColDef[] = [
    {
      field: 'exported',
      headerName: `${t("Exported")}`,
      headerAlign: 'center',
      width: 120,
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      pinnable: true,
      renderCell: (params: GridCellParams) =>
        params.row.exported === true ? (
          <Chip variant="outlined" color="success" label={t("Exported")} size="small" />
        ) : (
          <Chip variant="outlined" color="warning" label={t("Not Exported")} size="small" />
        )
    },
    {
      field: 'edit',
      headerName: `${t('Edit')}`,
      headerAlign: 'center',
      align: 'center',
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      pinnable: true,
      width: userRoleType === RoleType.Super_Admin ? 60 : 0,
      renderCell: (params) => {
        if (params.row.exported === false && (userRoleType === RoleType.Super_Admin || RoleType.HR_Recruiter)) {
          return (
            <Tooltip title={t("Edit")}>
              <Icon
                onClick={() => {
                  setClickedId(params.row.id)
                  setEditClicked(true)
                }}
                sx={{ cursor: "pointer", color: theme.palette.button.main }}
              >
                edit
              </Icon>
            </Tooltip>
          )
        }
      },
    },
    {
      field: 'exported_date',
      headerName: `${t('Exported Date')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => Date.parse(params.row.exported_date) ,
      renderCell: (params: GridCellParams) => typeof params.row.exported_date === 'string' ? dayjs(params.row.exported_date).format('DD/MM/YYYY HH:mm') :'-'
    },
    {
      field: 'first_name_th',
      headerName: `${t('First name (Thai language)')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.first_name_th,
      renderCell: (params: GridCellParams) => params.row.first_name_th ?? '-'
    },
    {
      field: 'last_name_th',
      headerName: `${t('Last name (Thai language)')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.last_name_th,
      renderCell: (params: GridCellParams) => params.row.last_name_th ?? '-'
    },
    {
      field: 'position',
      headerName: `${t('Position Id')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.position,
      renderCell: (params: GridCellParams) => params.row.position ?? '-'
    },
    {
      field: 'company',
      headerName: `${t('Company')}`,
      width: 200,
      valueGetter: (params: GridCellParams) => params && mapNameCompany(params.row.company),
    },
    {
      field: 'work_schedule',
      headerName: `${t('Work Schedule')}`,
      width: 190,
      valueGetter: (params: GridCellParams) => params.row.work_schedule,
      renderCell: (params: GridCellParams) => params.row.work_schedule ?? '-'
    },
    {
      field: 'pay_group',
      headerName: `${t('Pay Group')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.pay_group,
      renderCell: (params: GridCellParams) => params.row.pay_group ?? '-'
    },
    {
      field: 'holiday_schedule',
      headerName: `${t('Holiday Schedule')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.holiday_schedule,
      renderCell: (params: GridCellParams) => params.row.holiday_schedule ?? '-'
    },
    {
      field: 'amount_101',
      headerName: `${t('101_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_101,
      renderCell: (params: GridCellParams) => params.row.amount_101 ?? '-'
    },
    {
      field: 'amount_102',
      headerName: `${t('102_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_102,
      renderCell: (params: GridCellParams) => params.row.amount_102 ?? '-'
    },
    {
      field: 'amount_103_1',
      headerName: `${t('103_1_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_103_1,
      renderCell: (params: GridCellParams) => params.row.amount_103_1 ?? '-'
    },
    {
      field: 'amount_125',
      headerName: `${t('125_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_125,
      renderCell: (params: GridCellParams) => params.row.amount_125 ?? '-'
    },
    {
      field: 'amount_128',
      headerName: `${t('128_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_128,
      renderCell: (params: GridCellParams) => params.row.amount_128 ?? '-'
    },
    {
      field: 'amount_133',
      headerName: `${t('133_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_133,
      renderCell: (params: GridCellParams) => params.row.amount_133 ?? '-'
    },
    {
      field: 'amount_163',
      headerName: `${t('163_Amount')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.amount_163,
      renderCell: (params: GridCellParams) => params.row.amount_163 ?? '-'
    },
    {
      field: 'site_id',
      headerName: `${t('Site')}`,
      width: 200,
      valueGetter: (params: GridCellParams) => params && mapSites(params.row.site_id),
    },
    {
      field: 'national_id',
      headerName: `${t('National ID')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.national_id,
      renderCell: (params: GridCellParams) => params.row.national_id ?? '-'
    },
    {
      field: 'country_of_national_id',
      headerName: `${t('Country of National ID')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.country_of_national_id,
      renderCell: (params: GridCellParams) => params.row.country_of_national_id ?? '-'
    },
    {
      field: 'national_id_card_type',
      headerName: `${t('National ID Card Type')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.national_id_card_type,
      renderCell: (params: GridCellParams) => params.row.national_id_card_type ?? '-'
    },
    {
      field: 'country_of_birth',
      headerName: `${t('Country of birth')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.country_of_birth,
      renderCell: (params: GridCellParams) => params.row.country_of_birth ?? '-'
    },
    {
      field: 'date_of_birth',
      headerName: `${t('Date of birth')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.date_of_birth,
      renderCell: (params: GridCellParams) => params.row.date_of_birth ?? '-'
    },
    {
      field: 'prefix',
      headerName: `${t('Prefix')}`,
      width: 100,
      valueGetter: (params: GridCellParams) => params.row.prefix,
      renderCell: (params: GridCellParams) => params.row.prefix ?? '-'
    },
   
    {
      field: 'first_name_en',
      headerName: `${t('First name (English language)')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.first_name_en,
      renderCell: (params: GridCellParams) => params.row.first_name_en ?? '-'
    },
    {
      field: 'last_name_en',
      headerName: `${t('Last name (Thai language)')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.last_name_en,
      renderCell: (params: GridCellParams) => params.row.last_name_en ?? '-'
    },
    {
      field: 'gender',
      headerName: `${t('Gender')}`,
      width: 100,
      valueGetter: (params: GridCellParams) => params.row.gender,
      renderCell: (params: GridCellParams) => params.row.gender ?? '-'
    },
    {
      field: 'nationality',
      headerName: `${t('Nationality')}`,
      width: 100,
      valueGetter: (params: GridCellParams) => params.row.nationality,
      renderCell: (params: GridCellParams) => params.row.nationality ?? '-'
    },
    {
      field: 'marital_status',
      headerName: `${t('Marital Status')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.marital_status,
      renderCell: (params: GridCellParams) => params.row.marital_status ?? '-'
    },
    {
      field: 'registered_sso',
      headerName: `${t('Registered SSO')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.registered_sso,
      renderCell: (params: GridCellParams) => params.row.registered_sso ?? '-'
    },
    {
      field: 'sso_hospital',
      headerName: `${t('SSO Hospital')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.sso_hospital,
      renderCell: (params: GridCellParams) => params.row.sso_hospital ?? '-'
    },
    {
      field: 'religion',
      headerName: `${t('Religion')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.religion,
      renderCell: (params: GridCellParams) => params.row.religion ?? '-'
    },
    {
      field: 'phone_number',
      headerName: `${t('Telephone')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.phone_number,
      renderCell: (params: GridCellParams) => params.row.phone_number ?? '-'
    },
    {
      field: 'legal_country',
      headerName: `${t('Legal Country')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_country,
      renderCell: (params: GridCellParams) => params.row.legal_country ?? '-'
    },
    {
      field: 'legal_house_number',
      headerName: `${t('Legal House Number')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_house_number,
      renderCell: (params: GridCellParams) => params.row.legal_house_number ?? '-'
    },
    {
      field: 'legal_moo',
      headerName: `${t('Legal Moo')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_moo,
      renderCell: (params: GridCellParams) => params.row.legal_moo ?? '-'
    },
    {
      field: 'legal_street',
      headerName: `${t('Legal Street')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_street,
      renderCell: (params: GridCellParams) => params.row.legal_street ?? '-'
    },
    {
      field: 'legal_lane',
      headerName: `${t('Legal Lane')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_lane,
      renderCell: (params: GridCellParams) => params.row.legal_lane ?? '-'
    },
    {
      field: 'legal_sub_district',
      headerName: `${t('Legal Sub District')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_sub_district,
      renderCell: (params: GridCellParams) => params.row.legal_sub_district ?? '-'
    },
    {
      field: 'legal_district',
      headerName: `${t('Legal District')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_district,
      renderCell: (params: GridCellParams) => params.row.legal_district ?? '-'
    },
    {
      field: 'legal_province',
      headerName: `${t('Legal Province')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_province,
      renderCell: (params: GridCellParams) => params.row.legal_province ?? '-'
    },
    {
      field: 'legal_postal_code',
      headerName: `${t('Legal Postal Code')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.legal_postal_code,
      renderCell: (params: GridCellParams) => params.row.legal_postal_code ?? '-'
    },
    {
      field: 'same_as_legal',
      headerName: `${t('Same as Legal')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.same_as_legal,
      renderCell: (params: GridCellParams) => params.row.same_as_legal ?? '-'
    },
    {
      field: 'mailing_country',
      headerName: `${t('Mailing Country')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_country,
      renderCell: (params: GridCellParams) => params.row.mailing_country ?? '-'
    },
    {
      field: 'mailing_house_number',
      headerName: `${t('Mailing House Number')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_house_number,
      renderCell: (params: GridCellParams) => params.row.mailing_house_number ?? '-'
    },
    {
      field: 'mailing_moo',
      headerName: `${t('Mailing Moo')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_moo,
      renderCell: (params: GridCellParams) => params.row.mailing_moo ?? '-'
    },
    {
      field: 'mailing_street',
      headerName: `${t('Mailing Street')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_street,
      renderCell: (params: GridCellParams) => params.row.mailing_street ?? '-'
    },
    {
      field: 'mailing_lane',
      headerName: `${t('Mailing Lane')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_lane,
      renderCell: (params: GridCellParams) => params.row.mailing_lane ?? '-'
    },
    {
      field: 'mailing_sub_district',
      headerName: `${t('Mailing Sub District')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_sub_district,
      renderCell: (params: GridCellParams) => params.row.mailing_sub_district ?? '-'
    },
    {
      field: 'mailing_district',
      headerName: `${t('Mailing District')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_district,
      renderCell: (params: GridCellParams) => params.row.mailing_district ?? '-'
    },
    {
      field: 'mailing_province',
      headerName: `${t('Mailing Province')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_province,
      renderCell: (params: GridCellParams) => params.row.mailing_province ?? '-'
    },
    {
      field: 'mailing_postal_code',
      headerName: `${t('Mailing Postal Code')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.mailing_postal_code,
      renderCell: (params: GridCellParams) => params.row.mailing_postal_code ?? '-'
    },
    {
      field: 'hire_date',
      headerName: `${t('Hire Date')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.hire_date,
      renderCell: (params: GridCellParams) => params.row.hire_date ?? '-'
    },
    
    {
      field: 'pay_grade',
      headerName: `${t('Pay Grade')}`,
      width: 100,
      valueGetter: (params: GridCellParams) => params.row.pay_grade,
      renderCell: (params: GridCellParams) => params.row.pay_grade ?? '-'
    },
    {
      field: 'incentive_group',
      headerName: `${t('Incentive Group')}`,
      width: 100,
      valueGetter: (params: GridCellParams) => params.row.incentive_group,
      renderCell: (params: GridCellParams) => params.row.incentive_group ?? '-'
    },
    {
      field: 'time_reporter_type',
      headerName: `${t('Time Reporter Type')}`,
      width: 100,
      valueGetter: (params: GridCellParams) => params.row.time_reporter_type,
      renderCell: (params: GridCellParams) => params.row.time_reporter_type ?? '-'
    },
   
    {
      field: 'holiday_schedule',
      headerName: `${t('Holiday Schedule')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.holiday_schedule,
      renderCell: (params: GridCellParams) => params.row.holiday_schedule ?? '-'
    },
    {
      field: 'work_group',
      headerName: `${t('Work Group')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.work_group,
      renderCell: (params: GridCellParams) => params.row.work_group ?? '-'
    },
    {
      field: 'utilized',
      headerName: `${t('Utilized')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.utilized,
      renderCell: (params: GridCellParams) => params.row.utilized ?? '-'
    },
    {
      field: 'contract_start_date',
      headerName: `${t('Contract Start Date')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.contract_start_date,
      renderCell: (params: GridCellParams) => params.row.contract_start_date ?? '-'
    },
    {
      field: 'contract_end_date',
      headerName: `${t('Contract End Date')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.contract_end_date,
      renderCell: (params: GridCellParams) => params.row.contract_end_date ?? '-'
    },
    {
      field: 'bank_account_number',
      headerName: `${t('Bank Account Number')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.bank_account_number,
      renderCell: (params: GridCellParams) => params.row.bank_account_number ?? '-'
    },
    {
      field: 'bank',
      headerName: `${t('Bank')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.bank,
      renderCell: (params: GridCellParams) => params.row.bank ?? '-'
    },
    {
      field: 'job_relationship_email',
      // headerName: `${t(`Job Relationship's Email`)}`,
      headerName: `${t(`HR rep's e-mail`)}`,
      width: 150,
      valueGetter: (params: GridCellParams) => params.row.job_relationship_email,
      renderCell: (params: GridCellParams) => params.row.job_relationship_email ?? '-'
    },
    
    
  ];

  // const handleExportCSV = () => {
  //   try {
  //     const fetchECData = async () => {
  //       const response = await getECExport();

  //       // const response = await getECData({
  //       //   site_ids: selectedSites.map(site => site.site_id),
  //       //   job_ids: selectedJobs.map(job => job.id),
  //       //   ec_ids: ECRowsSelected.map((row: any) => row)
  //       // });
    

  //       // if (!response.data?.success) {
  //       //   Swal.fire({
  //       //     icon: 'error',
  //       //     title: 'Oops...',
  //       //     text: `${t("Something went wrong")}`,
  //       //   })

  //       //   return;
  //       // }

  //       const ecData = response.data?.ECData.data;
  //       const rows = ecData.map((row: ECDataType) => ({
  //         national_id: row.national_id,
  //         country_of_national_id: row.country_of_national_id,
  //         national_id_card_type: row.national_id_card_type,
  //         country_of_birth: row.country_of_birth,
  //         date_of_birth: row.date_of_birth,
  //         prefix: row.prefix,
  //         first_name_th: row.first_name_th,
  //         last_name_th: row.last_name_th,
  //         first_name_en: row.first_name_en,
  //         last_name_en: row.last_name_en,
  //         gender: row.gender,
  //         nationality: row.nationality,
  //         marital_status: row.marital_status,
  //         registered_sso: row.registered_sso,
  //         sso_hospital: row.sso_hospital,
  //         religion: row.religion,
  //         phone_number: row.phone_number,
  //         legal_country: row.legal_country,
  //         legal_house_number: row.legal_house_number,
  //         legal_moo: row.legal_moo,
  //         legal_street: row.legal_street,
  //         legal_lane: row.legal_lane,
  //         legal_room_no: row.legal_room_no,
  //         legal_floor: row.legal_floor,
  //         legal_building: row.legal_building,
  //         legal_village: row.legal_village,
  //         legal_sub_district: row.legal_sub_district,
  //         legal_district: row.legal_district,
  //         legal_province: row.legal_province,
  //         legal_postal_code: row.legal_postal_code,
  //         same_as_legal: row.same_as_legal,
  //         mailing_country: row.mailing_country,
  //         mailing_house_number: row.mailing_house_number,
  //         mailing_moo: row.mailing_moo,
  //         mailing_street: row.mailing_street,
  //         mailing_lane: row.mailing_lane,
  //         mailing_room_no: row.mailing_room_no,
  //         mailing_floor: row.mailing_floor,
  //         mailing_building: row.mailing_building,
  //         mailing_village: row.mailing_village,
  //         mailing_sub_district: row.mailing_sub_district,
  //         mailing_district: row.mailing_district,
  //         mailing_province: row.mailing_province,
  //         mailing_postal_code: row.mailing_postal_code,
  //         hire_date: row.hire_date,
  //         position: row.position,
  //         company: row.company,
  //         pay_grade: row.pay_grade,
  //         incentive_group: row.incentive_group,
  //         time_reporter_type: row.time_reporter_type,
  //         work_schedule: row.work_schedule,
  //         holiday_schedule: row.holiday_schedule,
  //         work_group: row.work_group,
  //         utilized: row.utilized,
  //         contract_start_date: row.contract_start_date,
  //         contract_end_date: row.contract_end_date,
  //         bank_account_number: row.bank_account_number,
  //         bank: row.bank,
  //         job_relationship_email: row.job_relationship_email,
  //         pay_group: row.pay_group,
  //         amount_101: row.amount_101,
  //         amount_102: row.amount_102,
  //         amount_103_1: row.amount_103_1,
  //         amount_125: row.amount_125,
  //         amount_128: row.amount_128,
  //         amount_133: row.amount_133,
  //         amount_163: row.amount_163,
  //       }));

  //       /* generate worksheet and workbook */
  //       const worksheet = XLSX.utils.json_to_sheet(rows);
  //       const workbook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workbook, worksheet, 'EC Data');

  //       /* fix headers */
  //       const headerRow = [
  //         "National ID",
  //         "Country of National ID",
  //         "National ID Card Type",
  //         "Country of Birth",
  //         "Date of Birth",
  //         "Prefix Name",
  //         "First Name TH",
  //         "Last Name TH",
  //         "First Name EN",
  //         "Last Name EN",
  //         "Gender",
  //         "Nationality",
  //         "Marital Status",
  //         "Registered SSO",
  //         "SSO Hospital",
  //         "Religion",
  //         "Phone Number",
  //         "Legal Country",
  //         "Legal House Number",
  //         "Legal Moo (THA)",
  //         "Legal Street",
  //         "Legal Lane",
  //         "Legal Room No",
  //         "Legal Floor",
  //         "Legal Building",
  //         "Legal Village",
  //         "Legal Sub District",
  //         "Legal District",
  //         "Legal Province",
  //         "Legal Postal code",
  //         "Same as Legal",
  //         "Mailing Country",
  //         "Mailing House Number",
  //         "Mailing Moo (THA)",
  //         "Mailing Street",
  //         "Mailing Lane",
  //         "Mailing Room No.",
  //         "Mailing Floor",
  //         "Mailing Bluiding",
  //         "Mailing Village",
  //         "Mailing Sub District",
  //         "Mailing District",
  //         "Mailing Province",
  //         "Mailing Postal code",
  //         "Hire Date",
  //         "Position",
  //         "Company",
  //         "Pay Grade",
  //         "Incentive Group",
  //         "Time Reporter Type",
  //         "Work Schedule",
  //         "Holiday Schedule",
  //         "Workgroup",
  //         "Utilized (Y/N)",
  //         "Contract Start Date",
  //         "Contract End Date",
  //         "Bank Account Number",
  //         "Bank",
  //         "Job Relationship Email",
  //         "Pay Group",
  //         "101_Amount",
  //         "102_Amount",
  //         "103_1_Amount",
  //         "125_Amount",
  //         "128_Amount",
  //         "133_Amount",
  //         "163_Amount"
  //       ];

  //       XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });

  //       /* calculate column width */
  //       const max_width = rows.reduce((w: number, r: { country_of_national_id: string[] }) => Math.max(w, r.country_of_national_id.length), 10);
  //       worksheet["!cols"] = [{ wch: max_width }];

  //       /* create an XLSX file and try to save to ECData + yyyyMMddHHmmss.xlsx */
  //       const now = new Date().toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$3$1$2_$4$5$6');

  //       XLSX.writeFile(workbook, `ECData_${now}.xlsx`);

  //       /* update exported status */
  //       await editECExportedStatus({
  //         ec_ids: ECRowsSelected.map((row: any) => row)
  //       });

  //       /* refetch EC data */
  //       window.location.reload();
  //     };

  //     fetchECData();
  //   } catch (error) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: `${t("Something went wrong")}`,
  //     })
  //   }
  // };

  const handleBtnCSV = () => {
    try {
      const fetchECData = async () => {
        // const response = await getECExport();

        const response = await getECData({
          site_ids: selectedSites.map(site => site.site_id),
          job_ids: selectedJobs.map(job => job.id),
          ec_ids: ECRowsSelected.map((row: any) => row)
        });
    

        if (!response.data?.success) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${t("Something went wrong")}`,
          })

          return;
        }

        const ecData = response.data?.ECData.data;
        const rows = ecData.map((row: ECDataType) => ({
          national_id: row.national_id,
          country_of_national_id: row.country_of_national_id,
          national_id_card_type: row.national_id_card_type,
          country_of_birth: row.country_of_birth,
          date_of_birth: row.date_of_birth,
          prefix: t(`${row.prefix}`),
          first_name_th: row.first_name_th,
          last_name_th: row.last_name_th,
          first_name_en: row.first_name_en,
          last_name_en: row.last_name_en,
          gender: row.gender,
          nationality: row.nationality,
          marital_status: row.marital_status,
          registered_sso: row.registered_sso,
          sso_hospital: row.sso_hospital,
          religion: row.religion,
          phone_number: row.phone_number,
          legal_country: row.legal_country,
          legal_house_number: row.legal_house_number,
          legal_moo: row.legal_moo,
          legal_street: row.legal_street,
          legal_lane: row.legal_lane,
          legal_room_no: row.legal_room_no,
          legal_floor: row.legal_floor,
          legal_building: row.legal_building,
          legal_village: row.legal_village,
          legal_sub_district: row.legal_sub_district,
          legal_district: row.legal_district,
          legal_province: row.legal_province,
          legal_postal_code: row.legal_postal_code,
          same_as_legal: row.same_as_legal,
          mailing_country: row.mailing_country,
          mailing_house_number: row.mailing_house_number,
          mailing_moo: row.mailing_moo,
          mailing_street: row.mailing_street,
          mailing_lane: row.mailing_lane,
          mailing_room_no: row.mailing_room_no,
          mailing_floor: row.mailing_floor,
          mailing_building: row.mailing_building,
          mailing_village: row.mailing_village,
          mailing_sub_district: row.mailing_sub_district,
          mailing_district: row.mailing_district,
          mailing_province: row.mailing_province,
          mailing_postal_code: row.mailing_postal_code,
          hire_date: row.hire_date,
          position: row.position,
          company: row.company,
          pay_grade: row.pay_grade,
          incentive_group: row.incentive_group,
          time_reporter_type: row.time_reporter_type,
          work_schedule: mapWorkSchedule(row.work_schedule), 
          holiday_schedule: row.holiday_schedule,
          work_group: row.work_group,
          utilized: row.utilized,
          contract_start_date: row.contract_start_date,
          contract_end_date: row.contract_end_date,
          bank_account_number: row.bank_account_number,
          bank: row.bank,
          job_relationship_email: row.job_relationship_email,
          pay_group: row.pay_group,
          amount_101: row.amount_101,
          amount_102: row.amount_102,
          amount_103_1: row.amount_103_1,
          amount_125: row.amount_125,
          amount_128: row.amount_128,
          amount_133: row.amount_133,
          amount_163: row.amount_163,
        }));

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'EC Data');

        /* fix headers */
        const headerRow = [
          "National ID",
          "Country of National ID",
          "National ID Card Type",
          "Country of Birth",
          "Date of Birth",
          "Prefix Name",
          "First Name TH",
          "Last Name TH",
          "First Name EN",
          "Last Name EN",
          "Gender",
          "Nationality",
          "Marital Status",
          "Registered SSO",
          "SSO Hospital",
          "Religion",
          "Phone Number",
          "Legal Country",
          "Legal House Number",
          "Legal Moo (THA)",
          "Legal Street",
          "Legal Lane",
          "Legal Room No",
          "Legal Floor",
          "Legal Building",
          "Legal Village",
          "Legal Sub District",
          "Legal District",
          "Legal Province",
          "Legal Postal code",
          "Same as Legal",
          "Mailing Country",
          "Mailing House Number",
          "Mailing Moo (THA)",
          "Mailing Street",
          "Mailing Lane",
          "Mailing Room No.",
          "Mailing Floor",
          "Mailing Bluiding",
          "Mailing Village",
          "Mailing Sub District",
          "Mailing District",
          "Mailing Province",
          "Mailing Postal code",
          "Hire Date",
          "Position",
          "Company",
          "Pay Grade",
          "Incentive Group",
          "Time Reporter Type",
          "Work Schedule",
          "Holiday Schedule",
          "Workgroup",
          "Utilized (Y/N)",
          "Contract Start Date",
          "Contract End Date",
          "Bank Account Number",
          "Bank",
          "Job Relationship Email",
          "Pay Group",
          "101_Amount",
          "102_Amount",
          "103_1_Amount",
          "125_Amount",
          "128_Amount",
          "133_Amount",
          "163_Amount"
        ];

        XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });

        /* calculate column width */
        const max_width = rows.reduce((w: number, r: { country_of_national_id: string[] }) => Math.max(w, r.country_of_national_id.length), 10);
        worksheet["!cols"] = [{ wch: max_width }];

        /* create an XLSX file and try to save to ECData + yyyyMMddHHmmss.xlsx */
        const now = new Date().toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).replace(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/, '$3$1$2_$4$5$6');

        XLSX.writeFile(workbook, `ECData_${now}.xlsx`);

        /* update exported status */
        // await editECExportedStatus({
        //   ec_ids: ECRowsSelected.map((row: any) => row)
        // });

        /* refetch EC data */
        window.location.reload();
      };

      fetchECData();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${t("Something went wrong")}`,
      })
    }
  };

  


  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
          {t('DataToECPageTitle')}
        </Typography>
        {
          // userRoleType === RoleType.Super_Admin  && (
            <Button
              variant="contained"
              disabled={ECRowsSelected.length === 0}
              sx={{
                backgroundColor: theme.palette.button.main,
                color: 'white',
                '&:hover': {
                  backgroundColor: '#0288d1',
                },
                minWidth: 'fit-content',
                minHeight: '42px',
              }}
              onClick={handleBtnCSV}
            >
              {t("Export Excel")}
            </Button>
          // )
        }
      </Box>

      <Box sx={{ width: '100%', marginTop: 3, paddingX: 3 }}>
        <EditECData
          open={editClicked}
          keepMounted
          onClose={() => {
            setClickedId('')
            setEditClicked(false)
          }}
          ec_data_id={clickedId}
        />
        {
          !allSuccessLoading ? <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box> : (
            filterECDataData.length === 0 ? (
              <Typography align="center">{t('No data')}</Typography>

            ) : (<>
              <Box display='inline-grid' sx={{ width: '100%' }}>
                <DataGridPro
                  getRowId={(row) => row.id}
                  rows={filterECDataData}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={(params: GridRowSelectionModel) => {
                    setECRowsSelected(params);
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                    pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'exported', 'edit'] }
                  }}
                  pagination
                  pageSizeOptions={[10, 20, 30, 40, 50]}
                  getRowHeight={() => 50}
                  disableRowSelectionOnClick
                  sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                      outline: 'none !important',
                    },
                    fontSize: 13,
                  }}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  slotProps={{
                    filterPanel: {
                      filterFormProps: {
                        logicOperatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                        },
                        columnInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        operatorInputProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: { mt: 'auto' },
                        },
                        valueInputProps: {
                          InputComponentProps: {
                            variant: 'outlined',
                            size: 'small',
                          },
                        },
                        deleteIconProps: {
                          sx: {
                            '& .MuiSvgIcon-root': { color: '#d32f2f' },
                          },
                        },
                      },
                      sx: {
                        '& .MuiDataGrid-filterForm': { p: 2 },
                        '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                          backgroundColor: () =>
                            theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                        },
                        '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                        '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                        '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                      },
                    },
                  }}
                  localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                  unstable_headerFilters
                />
              </Box>
            </>)
          )
        }
      </Box>
    </>
  );
};

export default DataToEC;