// React
import React, { useEffect, useState } from "react";
import { Link as ReactRouter, useNavigate } from "react-router-dom";

// Hooks and Redux
import { useAppSelector } from "../../../redux/hooks";
import useDebounce from "../../../hooks/useDebounce";

// Components
import InputDataToDC from "../../../components/admin/InputDataToEC";

// MUI Components
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";

import {
  GridCellParams,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams
} from "@mui/x-data-grid";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider, MobileDatePicker, TimeField } from '@mui/x-date-pickers';
import { DataGridPro, enUS } from '@mui/x-data-grid-pro';
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";

// MUI Icons
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// Utils
import { useForm } from "react-hook-form";
import { pastShortcutsItems } from "../../../utils/DateRangePickerShortcuts";
import { newAdapter } from "../../../utils";
import dayjs from 'dayjs';
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Swal from "sweetalert2";

// Styles
import '../../../styles/SubmitForm.scss';
import theme from "../../../styles/theme";

// Types
import {
  AppFormStatus,
  Site,
  Status
} from "../../../@types";
import { thTHGrid } from "../../../@types/DataGridLocaleText";

// Services
import {
  getAllAppFormData,
  updateAppFormStatusByMultipleIds
} from "../../../services/AppFormService";
import { getAllSites } from "../../../services/SiteService";
import { getAllStatus } from "../../../services/StatusService";

//MODAL MUI STYLE
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  pt: 2,
  px: 4,
  pb: 3,
};

const SubmittedForm: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { rolesite } = useAppSelector((state) => state.user);

  //MODAL
  const [open, setOpen] = React.useState(false);
  const [dateErrorState, setDateErrorState] = useState<boolean>(false)
  const [timeErrorState, setTimeErrorState] = useState<boolean>(false)
  const handleClose = () => {
    setOpen(false)
    setDateData(null)
    setTimeData(null)
    setDateErrorState(false)
    setTimeErrorState(false)
    changeStatus()
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [appFormData, setAppFormData] = useState<AppFormStatus[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
  const [selectedSites, setSelectedSites] = useState<Site | null>(null);
  const [rowsSelected, setRowsSelected] = useState<any[]>([]);
  const [siteData, setSiteData] = useState<Site[]>([]);
  const [statusData, setStatusData] = useState<Status[]>([]);
  const [statusLoading, setStatusLoading] = useState<boolean>(true);
  const [appFormLoading, setAppFormLoading] = useState<boolean>(true);
  const [siteLoading, setSiteLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [countFilterSelected, setCountFilterSelected] = useState<number>(0);

  const [openDataToEC, setOpenDataToEC] = useState<boolean>(false);

  const [submittedDateRange, setSubmittedDateRange] = useState<any>([null, null]);
  const allSuccessLoading = !appFormLoading && !siteLoading && !statusLoading;
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  var [dateData, setDateData] = useState<any>(null);
  var [timeData, setTimeData] = useState<any>(null);

  const {
    register,
  } = useForm();

  useEffect(() => {
    const fetchSites = async () => {
      const response = await getAllSites();

      if (response.data?.success) {
        const data = response.data.sites;
        let dataActive = data.filter((site: Site) => site.is_active === true)
        let dataRole = dataActive.filter((site: Site) => rolesite.includes(site.site_id))
        setSiteData(dataRole);
        setSiteLoading(false);
      }
    };

    const fetchStatus = async () => {
      const response = await getAllStatus();

      if (response.data?.success) {
        setStatusData(response.data?.status);
        setStatusLoading(false);
      }
    };

    if (siteLoading === true) {
      fetchSites();
    }

    if (statusLoading === true) {
      fetchStatus();
    }

  }, [navigate, rolesite, siteLoading, statusLoading])

  useEffect(() => {
    const fetchAppForm = async () => {
      try {
        setAppFormLoading(true);

        const data = {
          //rolesite: rolesite,
          submittedDateRange: submittedDateRange,
          status: selectedStatus?.id,
          site: selectedSites?.site_id ? [selectedSites?.site_id] : null,
          query: searchQuery
        }
        
        const response = await getAllAppFormData(data);

        if (response.data?.success) {
          const data: AppFormStatus[] = response.data.appForms;
          setAppFormData(data);
        }

      } catch (error) {
        navigate('/servererror', { replace: true });
      } finally {
        setTimeout(() => {
          setAppFormLoading(false);
          setIsSearching(false);
        }, 500);
      }
    };

    if (debouncedSearchQuery !== '') {
      setIsSearching(true);
      fetchAppForm();
    } else {
      setSearchQuery('');
      fetchAppForm();
    }
  }, [navigate, selectedSites, selectedStatus, debouncedSearchQuery, submittedDateRange, rolesite]);

  useEffect(() => {
    if (searchQuery !== '') {
      setIsSearching(true);
      setAppFormData([]);
    }
  }, [searchQuery])

  useEffect(() => {
    let count = 0;
    if (selectedSites) count++;
    if (selectedStatus) count++;
    if (submittedDateRange[0] || submittedDateRange[1]) count++;
    setCountFilterSelected(count);
  }, [selectedSites, selectedStatus, submittedDateRange])

  const handleFilterOpenClick =
    (newPlacement: PopperPlacementType) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
      };

  const handleClickAway = () => {
    setFilterOpen(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'view',
      headerName: `${t('View')}`,
      headerAlign: 'center',
      width: 80,
      align: 'center',
      hideSortIcons: true,
      disableColumnMenu: true,
      filterable: false,
      //เปิด application-form/detail
      renderCell: (params) => {
        return (
          <Link component={ReactRouter} to={`/admin/application-form/detail/${params.row.id}`} style={{ marginLeft: 10, cursor: 'pointer', color: theme.palette.button.main }}>
            <Tooltip title={t("View more details")}>
              <Icon fontSize="medium">visibility ddd</Icon> 
            </Tooltip>
          </Link>
        );
      }
    },
    // created_date,'full_name', 'position'
    {
      field: 'created_date', 
      headerName: `${t("Apply Date")}`,
      width: 150, type: 'string',
      sortComparator: (v1, v2, params1, params2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
      //close it bc. sortDate is null 
      // valueGetter: (params: GridValueGetterParams) => {
      //   const date = dayjs(params.row.created_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
      //   const time = dayjs(params.row.created_date).format('HH:mm')
      //   return date + ' ' + time
      // },
      renderCell: (params: GridCellParams) => {
        const date = dayjs(params.row.created_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
        const time = dayjs(params.row.created_date).format('HH:mm')
        return date + ' ' + time
      },
    },
    {
      field: 'AppFormData.full_name',
      headerName: `${t('Full name')}`,
      width: 180,
      valueGetter: (params: GridCellParams) => {
        let fullName = params.row.AppFormData.first_name_th + ' ' + params.row.AppFormData.last_name_th;
        return fullName;
      }
    },
    {
      field: 'position',
      headerName: `${t('Position')}`,
      width: 180,
      valueGetter: (params: GridCellParams) => {
        let position = params.row.Job.job_name;
        return position;
      }
    },
    {
      field: 'show_on_hr',
      headerName: `${t('Status')}`,
      width: 150,
      renderCell: (params: GridCellParams) => {
        let statusHR = [params.row.Status.show_on_hr]
        let statusColor = [params.row.Status.color]

        return (
          <Chip
            variant="outlined"
            sx={{
              bgcolor: statusColor,
              borderColor: statusColor,
              color: 'white',
              my: 1.5
            }}
            size='medium'
            label={statusHR}
          />
        )
      },
      valueGetter: (params: GridCellParams) => {
        let statusHR = params.row.Status.show_on_hr;
        return statusHR;
      }
    },
    {
      field: 'telephone',
      headerName: `${t('Phone Number')}`,
      width: 150,
      valueGetter: (params: GridCellParams) => {
        let telephone = params.row.AppFormData.telephone;
        return telephone;
      }
    },
    {
      field: 'site',
      headerName: `${t('Site')}`,
      width: 180,
      valueGetter: (params: GridCellParams) => {
        let site = params.row.Job.Site.site_name;
        return site;
      }
    },
    {
      field: 'company',
      headerName: `${t('Job Company')}`,
      width: 200,
      renderCell: (params: GridCellParams) => {
        let company = [params.row.Job.company]
        return company
      }
    },
    {
      field: 'modified_date', headerName: `${t("Last Modified Date")}`, width: 150, type: 'string',
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.modified_date) {
          const date = dayjs(params.row.modified_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
          const time = dayjs(params.row.modified_date).format('HH:mm')
          return date + ' ' + time;
        } else {
          return null;
        }
      },
      renderCell: (params: GridCellParams) => {
        if (params.row.modified_date) {
          const date = dayjs(params.row.modified_date).format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB')
          const time = dayjs(params.row.modified_date).format('HH:mm')
          return date + ' ' + time;
        } else {
          return '-';
        }
      },
    },
  ];

  function changeStatus() {
    const parsedToken: any = jwtDecode(localStorage.access_token);

    Swal.fire({
      title: `${t('Select Status')}`,
      html:
        `<label class="changeorange-container" ><input id="consider" type="checkbox" name="check" /><span class="orange">${statusData[1].show_on_hr}</span></label>` +
        `<label class="changeorange-container" ><input id="interview" type="checkbox" name="check" /><span class="orange">${statusData[2].show_on_hr}</span></label>` +
        `<label class="changegreen-container" ><input id="pass" type="checkbox" name="check" /><span class="green">${statusData[3].show_on_hr}</span></label>` +
        `<label class="changered-container" ><input id="fail" type="checkbox" name="check" /><span class="red">${statusData[4].show_on_hr}</span></label>` +
        '<label class="changered-container" ><input type="checkbox" name="check" /></label>',
      showCancelButton: true,
      confirmButtonColor: '#43A047',
      cancelButtonText: `${t('Cancel')}`,
      confirmButtonText: `${t('Confirm')}`,
      didOpen: () => {
        var consider = document.getElementById('consider') as HTMLInputElement
        var interview = document.getElementById('interview') as HTMLInputElement
        var pass = document.getElementById('pass') as HTMLInputElement
        var fail = document.getElementById('fail') as HTMLInputElement
        var groupCheck = Array.from(document.getElementsByName('check'))

        consider.addEventListener("input", function () {
          Swal.resetValidationMessage()
          if (consider.checked) {
            groupCheck.forEach(element => {
              consider.checked = true;
              (element as HTMLInputElement).checked = false;
            })
          }
        })

        interview.addEventListener("input", function () {
          Swal.resetValidationMessage()
          if (interview.checked) {
            groupCheck.forEach(element => {
              interview.checked = true;
              (element as HTMLInputElement).checked = false;
            })
          }
        })

        pass.addEventListener("input", function () {
          Swal.resetValidationMessage()
          if (pass.checked) {
            groupCheck.forEach(element => {
              pass.checked = true;
              (element as HTMLInputElement).checked = false;
            })
          }
        })

        fail.addEventListener("input", function () {
          Swal.resetValidationMessage()
          if (fail.checked) {
            groupCheck.forEach(element => {
              fail.checked = true;
              (element as HTMLInputElement).checked = false;
            })
          }
        })
      },
      preConfirm: () => {
        var checkConsider = (document.getElementById('consider') as HTMLInputElement).checked
        var checkInterview = (document.getElementById('interview') as HTMLInputElement).checked
        var checkPass = (document.getElementById('pass') as HTMLInputElement).checked
        var checkFail = (document.getElementById('fail') as HTMLInputElement).checked

        if (!checkConsider && !checkInterview && !checkFail && !checkPass) {
          Swal.showValidationMessage(`${t('Status is required')}`)
        }

        if (checkConsider === true) {
          Swal.fire({
            icon: 'warning',
            title: `${t('Are you sure?')}`,
            html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-bd1fyz-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[1].show_on_hr}</span></div> <br>${t('Please check the information before change status')}`,
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const changeStatusFunction = async () => {
                try {
                  const data = {
                    ids: rowsSelected,
                    status_id: 2,
                    modified_by: parsedToken.Username
                  }

                  const response = await updateAppFormStatusByMultipleIds(data);

                  if (response.data?.success) {
                    Swal.fire({
                      icon: 'success',
                      title: `${t('Update status successful!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      window.location.reload();
                    })
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: `${t('Update status failed!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      changeStatus();
                    })
                  }
                } catch (error) {
                  navigate('/servererror', { replace: true });
                }
              }
              changeStatusFunction();
            } else {
              changeStatus();
            }
          })
        }

        if (checkInterview === true) {
          setOpen(true)
        }

        if (checkPass === true) {
          setOpenDataToEC(true)
        }

        if (checkFail === true) {
          Swal.fire({
            icon: 'warning',
            title: `${t('Are you sure?')}`,
            html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-1i5jzc9-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[4].show_on_hr}</span></div> <br>${t('Please check the information before change status')}`,
            showCancelButton: true,
            confirmButtonColor: '#43A047',
            cancelButtonText: `${t('Cancel')}`,
            confirmButtonText: `${t('Confirm')}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const changeStatusFunction = async () => {
                try {
                  const data = {
                    ids: rowsSelected,
                    status_id: 5,
                    modified_by: parsedToken.Username
                  }

                  const response = await updateAppFormStatusByMultipleIds(data);

                  if (response.data?.success) {
                    Swal.fire({
                      icon: 'success',
                      title: `${t('Update status successful!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      window.location.reload();
                    })
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: `${t('Update status failed!')}`,
                      confirmButtonColor: '#43A047',
                      confirmButtonText: 'OK!',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(() => {
                      changeStatus();
                    })
                  }
                } catch (error) {
                  navigate('/servererror', { replace: true });
                }
              }
              changeStatusFunction();
            } else {
              changeStatus();
            }
          })
        }
      }
    })
  }

  function handleInterview() {
    const parsedToken: any = jwtDecode(localStorage.access_token);
    setOpen(false)

    Swal.fire({
      icon: 'warning',
      title: `${t('Are you sure?')}`,
      html: `${t('The status will be changed to')}&nbsp&nbsp<div class="MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-outlinedDefault css-bd1fyz-MuiChip-root"><span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">${statusData[2].show_on_hr}</span></div> <br>${t('The date is on')} ` + dateData.format(i18n.language === 'en' ? 'DD/MM/YYYY' : 'DD/MM/BBBB') + `&nbsp${t('InterviewTimeAt')} ` + new Date(timeData).toLocaleString(i18n.language).split(' ')[1].split(':')[0] + ':' + new Date(timeData).toLocaleString(i18n.language).split(' ')[1].split(':')[1] + `<br>${t('Please check the information before change status')}`,
      showCancelButton: true,
      confirmButtonColor: '#43A047',
      cancelButtonText: `${t('Cancel')}`,
      confirmButtonText: `${t('Confirm')}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then((result) => {
        if (result.isConfirmed) {
          const changeStatusFunction = async () => {
            try {
              const data = {
                ids: rowsSelected,
                status_id: 3,
                modified_by: parsedToken.Username,
                interview_date: dateData?.format('YYYY-MM-DD') + " " + timeData?.format('HH:mm')
              }

              const response = await updateAppFormStatusByMultipleIds(data);

              if (response.data?.success) {
                Swal.fire({
                  icon: 'success',
                  title: `${t('Update status successful!')}`,
                  confirmButtonColor: '#43A047',
                  confirmButtonText: 'OK!',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then(() => {
                  window.location.reload();
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `${t('Update status failed!')}`,
                  confirmButtonColor: '#43A047',
                  confirmButtonText: 'OK!',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then(() => {
                  changeStatus();
                })
              }
            } catch (error) {
              navigate('/servererror', { replace: true });
            }
          }
          changeStatusFunction();
        } else {
          setOpen(true)
        }
      })
  }

  return <div>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
        {t('Submitted Application Form')}
      </Typography>
      <Button
        startIcon={<DescriptionIcon />}
        sx={{ textDecoration: 'underline', }}
        component={ReactRouter}
        to={`/admin/application-form/status-description`}>
        {t('Status Description')}
      </Button>
    </Box>

    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <InputDataToDC
        app_form_status_id={rowsSelected}
        app_form_Data={appFormData}
        keepMounted
        open={openDataToEC}
        onClose={() => setOpenDataToEC(false)}
      />
    </Box>

    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <Box display='flex' justifyContent='center' sx={{ p: 2 }}>
          <Typography variant="h5">{t("Interviewing")}</Typography>
        </Box>
        <Stack spacing={3} direction="row" display='flex' justifyContent='center' alignItems='center' sx={{ p: 1 }}>
          <Typography>{t("Date")}</Typography>
          <LocalizationProvider dateAdapter={newAdapter} adapterLocale='th'>
            <MobileDatePicker
              format="DD/MM/YYYY"
              localeText={{ 'okButtonLabel': `${t('ok')}`, 'cancelButtonLabel': `${t('Cancel')}` }}
              value={dateData}
              dayOfWeekFormatter={(_day, weekday) => `${weekday.format('dd')}.`}
              disablePast
              {...register("date", { required: true })}
              onChange={(newValue) => {
                try {
                  if (newValue !== null) {
                    setDateData(newValue)
                    setDateErrorState(false)
                  }
                } catch (error) { }
              }}
              slotProps={{
                textField: () => ({
                  color: dateErrorState === true ? 'error' : 'info',
                  focused: dateErrorState === true ? true : false,
                }),
              }}
            />
          </LocalizationProvider>
        </Stack>
        {(dateErrorState) ?
          (<Typography variant="inherit" color="error" align="right" ml={1} pb={1} pr={1} fontSize={13}>
            {t("Date is requried")}
          </Typography>)
          : null
        }

        <Stack spacing={2} direction="row" display='flex' justifyContent='center' alignItems='center' sx={{ p: 1 }}>
          <Typography>{t("Time")}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
              value={timeData}
              format="HH:mm"
              onChange={(newValue) => {
                setTimeData(newValue)
                setTimeErrorState(false)
              }}
              slotProps={{
                textField: () => ({
                  color: timeErrorState === true ? 'error' : 'info',
                  focused: timeErrorState === true ? true : false,
                }),
              }}
            />
          </LocalizationProvider>
        </Stack>
        {(timeErrorState) ?
          (<Typography variant="inherit" color="error" align="right" ml={1} pb={1} pr={1} fontSize={13}>
            {t("Time is requried")}
          </Typography>)
          : null
        }

        <Stack spacing={2} direction="row" display='flex' justifyContent='center' sx={{ p: 1, pt: 3 }}>
          <Button variant="contained"
            disableElevation
            onClick={() => {
              if (dateData === null && timeData === null) {
                setDateErrorState(true)
                setTimeErrorState(true)
              } else
                if (dateData === null) {
                  setDateErrorState(true)
                } else
                  if (timeData === null) {
                    setTimeErrorState(true)
                  } else {
                    handleInterview()
                    setDateErrorState(false)
                    setTimeErrorState(false)
                  }
            }}
          >
            {t("Confirm")}
          </Button>
          <Button variant="contained"
            disableElevation
            sx={{
              backgroundColor: '#797979',
              "&:hover": {
                backgroundColor: '#585858',
                color: 'white',
              },
            }}
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
        </Stack>
      </Box>
    </Modal>

    <Box>
      <Grid container spacing={3} sx={{ marginTop: 1, paddingX: 3 }}>
        <Grid item xs={12} sm={12}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              label={t("Search")}
              placeholder={t("Search by Fullname, id card number, phone number, email, job position, site")!}
              value={searchQuery}
              onChange={((event) => setSearchQuery(event.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', marginTop: 3, paddingX: 3 }}>

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: { sm: 'fit-content(100%) 2fr' },
          gridTemplateRows: { xs: 'fit-content(100%) repeat(1, 1fr)' },
          marginBottom: 2,
        }}>
          <Box sx={{ gridRow: 1, gridColumn: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              disabled={selectedStatus ? (rowsSelected.length === 0 ? true : false) : true}
              variant="contained"
              sx={{
                borderRadius: 1, backgroundColor: theme.palette.button.main, fontSize: 13,
                "&:hover": {
                  backgroundColor: '#0057FF',
                },
              }}
              onClick={() => changeStatus()}
            >
              {t('change status')}
            </Button>
            {
              selectedStatus ? <>
                <Typography variant="subtitle2" sx={{ display: 'inline-flex' }}>
                  <CheckCircleOutlineIcon sx={{ color: '#4CAF50', mr: 1 }} />
                  {rowsSelected.length} {t('selected')}
                </Typography>
              </> : <>
                <Typography variant="subtitle2" sx={{ display: 'inline-flex' }}>
                  <WarningAmberIcon sx={{ color: '#FFA726', mr: 1 }} />
                  {t('You must filter the status before selecting multiple application form')}
                </Typography>
              </>
            }
          </Box>

          <Box sx={{ gridRow: { xs: 2, sm: 1 }, gridColumn: { sm: 3 } }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '50% 50%', sm: 'repeat(1, 1fr)' } }}>
              <Box sx={{
                py: 1, display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end' }, '&:hover': {
                  cursor: 'pointer',
                }, gridColumn: 1
              }}>
                <Button onClick={handleFilterOpenClick('bottom-end')}>
                  <Badge badgeContent={countFilterSelected} color="primary">
                    <Icon >filter_list</Icon>
                  </Badge>
                  <Typography sx={{ userSelect: 'none' }}>
                    {t("Filter")}
                  </Typography>
                </Button>

                <Popper open={filterOpen} anchorEl={anchorEl} placement={placement} transition>
                  {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Fade {...TransitionProps} timeout={50}>
                        <Paper sx={{ width: '400px', display: 'flex', flexDirection: 'column', py: 1, px: 3, border: '1px solid #e0e0e0' }}>
                          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Box sx={{ width: '100%' }}>
                              <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterByStatus")}</Typography>
                              <FormControl sx={{ width: '100%' }}>
                                <Autocomplete
                                  onChange={(_, newValue: Status | null) => {
                                    setSelectedStatus(newValue);
                                  }}
                                  size="small"
                                  limitTags={1}
                                  clearIcon={null}
                                  id="status"
                                  options={statusData}
                                  getOptionLabel={(option) => option.show_on_hr}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  value={selectedStatus}
                                  renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.show_on_hr, inputValue, { insideWords: true });
                                    const parts = parse(option.show_on_hr, matches);

                                    return (
                                      <li {...props}>
                                        <div>
                                          {parts.map((part: any, index: any) => (
                                            <span
                                              key={index}
                                              style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                              }}
                                            >
                                              {part.text}
                                              <Chip size="small" sx={{ ml: 1, bgcolor: option.color, color: 'white' }} label={option.show_on_hr} />
                                            </span>
                                          ))}
                                        </div>
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params}
                                      label={t("Status")}
                                      placeholder={t("Status")!}
                                    />
                                  )}
                                  loading={statusLoading}
                                  loadingText={`${t('Loading')}...`}
                                />
                              </FormControl>
                            </Box>
                            <Tooltip title={t("Clear")}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ height: 'fit-content', ml: 1 }}
                                onClick={() => {
                                  setSelectedStatus(null);
                                }}
                              >
                                <Icon>clear</Icon>
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Box sx={{ width: '100%' }}>
                              <Typography sx={{ mt: 2, mb: 1, fontSize: '0.875rem' }}>{t("FilterBySite")}</Typography>
                              <FormControl sx={{ width: '100%' }}>
                                <Autocomplete
                                  onChange={(_, newValue: any) => {
                                    setSelectedSites(newValue);
                                  }}
                                  clearIcon={null}
                                  size="small"
                                  limitTags={1}
                                  id="site"
                                  options={siteData}
                                  getOptionLabel={(option) => option.site_name}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  value={selectedSites}
                                  renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.site_name, inputValue, { insideWords: true });
                                    const parts = parse(option.site_name, matches);

                                    return (
                                      <li {...props}>
                                        <div>
                                          {parts.map((part: any, index: any) => (
                                            <span
                                              key={index}
                                              style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                              }}
                                            >

                                              {part.text}
                                            </span>
                                          ))}
                                        </div>
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params}
                                      label={t("Site")}
                                      placeholder={t("Site")!}
                                    />
                                  )}
                                  loading={siteLoading}
                                  loadingText={`${t('Loading')}...`}
                                />
                              </FormControl>
                            </Box>
                            <Tooltip title={t("Clear")}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ height: 'fit-content', ml: 1 }}
                                onClick={() => {
                                  setSelectedSites(null);
                                }}
                              >
                                <Icon>clear</Icon>
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
                            <LocalizationProvider dateAdapter={newAdapter} adapterLocale={i18n.language === 'en' ? 'en' : 'th'}>
                              <DemoContainer components={['SingleInputDateRangeField']}>
                                <DemoItem label={t("FilterBySubmittedDate")} component="DateRangePicker">
                                  <MobileDateRangePicker
                                    slotProps={{
                                      shortcuts: {
                                        items: i18n.language === 'en' ? pastShortcutsItems.en : pastShortcutsItems.th,
                                      },
                                      actionBar: { actions: [] },
                                      textField: { size: 'small' },
                                    }}
                                    localeText={{
                                      start: `${t('StartDateRange')}`,
                                      end: `${t('EndDateRange')}`,
                                    }}
                                    dayOfWeekFormatter={(_day, weekday) => i18n.language === 'en' ? `${weekday.format('ddd')}` : `${weekday.format('dd')}`}
                                    // calendars={1}
                                    onChange={(date: any) => {
                                      setSubmittedDateRange([
                                        date[0] ? dayjs(date[0]).startOf('day') : null,
                                        date[1] ? dayjs(date[1]).endOf('day') : null,
                                      ]);
                                    }}
                                    defaultValue={submittedDateRange}
                                    showDaysOutsideCurrentMonth
                                    format="DD/MM/YYYY"
                                    value={submittedDateRange}
                                  />
                                </DemoItem>
                              </DemoContainer>
                            </LocalizationProvider>
                            <Tooltip title={t("Clear")}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ height: 'fit-content', ml: 1 }}
                                onClick={() => {
                                  setSubmittedDateRange([null, null]);
                                }}
                              >
                                <Icon>clear</Icon>
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button
                              sx={{
                                fontSize: 13,
                              }}
                              onClick={() => {
                                setSubmittedDateRange([null, null]);
                                setSelectedSites(null);
                                setSelectedStatus(null);
                                setFilterOpen(false);
                              }}
                            >
                              {t('RemoveAllFilters')}
                            </Button>
                          </Box>
                        </Paper>
                      </Fade>
                    </ClickAwayListener>
                  )}
                </Popper>
              </Box>
            </Box>
          </Box>
        </Box>

        {isSearching && allSuccessLoading && <Typography align='center'>{t("Searching")}...</Typography>}
        {!allSuccessLoading ? <Box display='flex' justifyContent='center' gap={2}><CircularProgress color="success" size={20} /><Typography align='center'>{t("Loading")}...</Typography></Box> : (
          appFormData.length === 0 ? (
            !isSearching ? (
              <Typography align='center'>{t("No data")}</Typography>
            ) : null
          ) : (<>
            <Box display='inline-grid' sx={{ width: '100%' }}>
              <DataGridPro
                getRowId={(row) => row.id}
                rows={appFormData}
                columns={columns}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20, 30, 40, 50]}
                disableRowSelectionOnClick={selectedStatus ? false : true}
                checkboxSelection={selectedStatus ? true : false}
                onRowSelectionModelChange={(params) => {
                  setRowsSelected(params);
                }}
                sx={{
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                    outline: 'none !important',
                  },
                  fontSize: 13,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
                slotProps={{
                  filterPanel: {
                    filterFormProps: {
                      logicOperatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                      },
                      columnInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                      },
                      operatorInputProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: { mt: 'auto' },
                      },
                      valueInputProps: {
                        InputComponentProps: {
                          variant: 'outlined',
                          size: 'small',
                        },
                      },
                      deleteIconProps: {
                        sx: {
                          '& .MuiSvgIcon-root': { color: '#d32f2f' },
                        },
                      },
                    },
                    sx: {
                      '& .MuiDataGrid-filterForm': { p: 2 },
                      '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                        backgroundColor: () =>
                          theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                      },
                      '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                      '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                    },
                  },
                }}
                localeText={i18n.language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : thTHGrid}
                unstable_headerFilters
              />
            </Box>
          </>)
        )
        }
      </Box>
    </Box>

  </div>;
};

export default SubmittedForm;
