import React from 'react';

// Hooks and Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { fetchDocumentByUser } from '../../../../redux/slices/documentsSlice';

import jwtDecode from "jwt-decode";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import {
  MultipleFileUpload,
  MultipleFileUploadStatusItem,
} from '@patternfly/react-core';
import "@patternfly/react-core/dist/styles/base.css";
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import './index.scss'; 
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import {Stack } from '@mui/material';
// Services
import {
  sendFilesDocuments,
  deleteDocuments
} from "../../../../services/UserService";
// Types
import {
  TokenPayload
} from "../../../../@types";
interface readFile {
  fileName: string;
  data?: string;
  loadResult?: 'danger' | 'success';
  loadError?: DOMException;
}

interface documentFile {
  id: string;
  name: string;
  type: string;
  dmsFileId: string;
}
interface statusFileUploadStatus {
  data: any[];
}

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

const FileUploadStatus: React.FunctionComponent<statusFileUploadStatus>  = (props) => {
  const { t } = useTranslation();
  const { register } = useForm();
  const { data } = props;

  const token = localStorage.getItem('access_token') || ''
  const decoded: TokenPayload = jwtDecode(token) 

  const [userFiles, setUserFiles ] = React.useState<File[]>([]);
  const [currentFiles, setCurrentFiles] = React.useState<File[]>([]);
  const [readFileData, setReadFileData] = React.useState<readFile[]>([]);
  const [showStatus, setShowStatus] = React.useState(false);
  const [statusIcon, setStatusIcon] = React.useState('inProgress');
  const [documentData, setDocumentData] = React.useState<[]>([]);
  const [loading, setLoading] = React.useState(false);

  const { document } = useAppSelector(state => state.documentDMS);
  const dispatch = useAppDispatch();
  const UserId = decoded?.UserId ? decoded.UserId : "";

    if (!showStatus && currentFiles.length > 0) {
    setShowStatus(true);
  }

    React.useEffect(() => {
      const fetchData = async () => {
        await dispatch(fetchDocumentByUser())
      }
      fetchData()
    }, [dispatch]);


    React.useEffect(() => {
        if (document) {
          const documentItems = document.filter((item: any) => item.type !== "EMPLOYMENT_REFERENCE")
          setDocumentData(documentItems)
        }
    }, [document]);



  React.useEffect(() => {
    if (readFileData.length < currentFiles.length) {
      setStatusIcon('inProgress');
    } else if (readFileData.every((file) => file.loadResult === 'success')) {
      setStatusIcon('success');
    } else {
      setStatusIcon('danger');
    }
  }, [readFileData, currentFiles]);

  
  const removeFiles = (namesOfFilesToRemove: string[]) => {
    const newCurrentFiles = userFiles.filter(
      (currentFile) => !namesOfFilesToRemove.some((fileName) => fileName === currentFile.name)
    );
    setUserFiles(newCurrentFiles) //
    setCurrentFiles(newCurrentFiles);

    const newReadFiles = readFileData.filter(
      (readFile) => !namesOfFilesToRemove.some((fileName) => fileName === readFile.fileName)
    );

    setReadFileData(newReadFiles);
  };


  const handleReadSuccess = (data: string, file: File) => {
    setReadFileData((prevReadFiles) => [...prevReadFiles, { data, fileName: file.name, loadResult: 'success' }]);
  };

  const handleReadFail = (error: DOMException, file: File) => {
    setReadFileData((prevReadFiles) => [
      ...prevReadFiles,
      { loadError: error, fileName: file.name, loadResult: 'danger' }
    ]);
  };

  const filterBySize = (file:any) => {
    return file[0].size <= MAX_FILE_SIZE;
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>, type:string) => {
    let files = e.target.files;
    let idstokeep = [0, 2]; 
    const checkSize = filterBySize(files)

    if (checkSize === true) {
      let droppedFile = Array.prototype.slice.call(files).splice(idstokeep[0], idstokeep[1]);

      const currentFileNames = userFiles.map((file) => file.name);

      const reUploads = droppedFile.filter((file) => {
        file.typeUpload = type
        return currentFileNames.includes(file.name)
      });

    Promise.resolve()
      .then(() => removeFiles(reUploads.map((file) => file.name)))
      .then(() => setUserFiles((prevFiles) => {
       return [...prevFiles, ...droppedFile]
      }));  

  } else {
    Swal.fire({
      icon: 'error',
      title: `${t('Error')}`,
      text: `${t("text_check_image_hight_2mb")}`,
      confirmButtonText: `${t('ok')}`,
  });
  
  } 
      
  };
 

  const handleImageUpload = async (event:any) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('uploadType', 'mandatory');
    formData.append("businessPlace",  data[0] && data[0]?.Job?.Site?.site_id ? data[0]?.Job?.Site?.site_id :'-');
    formData.append("company", data[0] && data[0]?.Job?.company ? data[0]?.Job?.company : '-' );

    for(let item in userFiles){
      let type: any = userFiles[item]
      formData.append(type?.typeUpload , userFiles[item]);
    }
    setLoading(true) 
    try {
      if(userFiles && userFiles.length > 0){
      const resFilesDoc = await sendFilesDocuments(formData, UserId)

      if (resFilesDoc && resFilesDoc.status === 200) {
        await dispatch(fetchDocumentByUser())
        setUserFiles([])
        setLoading(false)
      Swal.fire({
        icon: "success",
        title: `${t('Update status successful!')}` ,
        showConfirmButton: false,
        timer: 2000
      });
    }
  else{
    setLoading(false)
    const currentFile = userFiles && userFiles.filter((file) => file?.type === 'image/png' || file?.type === 'image/jpg' )
    Swal.fire({
        icon: 'warning',
        text: `${t('text_err_400_to_upload_file')} [${resFilesDoc.status}]`,
        confirmButtonText: `${t('ok')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          setUserFiles(currentFile)
        }
      })
     }
    }else{
        setLoading(false)
        Swal.fire({
          icon: 'warning',
          text: `${t('text_choose_image_before_click_upload')}`,
          confirmButtonText: `${t('ok')}`,
        })
    }
    } catch (error) {
      setLoading(false)
      // Handle error
    }

  }

  const handleStatusFile = (type: string) => {
    return   type === 'idCard' || type === "ID_CARD"
             ? "text_types_file_idcard"
             :type === 'houseRegistration' || type === "HOUSE_REGISTRATION"
             ? "text_types_file_houseRegistration"
             : type === 'bankAccount' || type === "BANK_ACCOUNT"
             ? "text_types_file_bankAccount"
             : type === 'formalPhoto' || type === "FORMAL_PHOTO"
             ? "text_types_file_formalPhoto"
             : ''
  };

  const handleDisabledBtn =(type: string) => {
    const newType =  type === "ID_CARD"
                   ?  'idCard'
                   : type === "HOUSE_REGISTRATION" 
                   ? 'houseRegistration'
                   : type === "BANK_ACCOUNT"
                   ? 'bankAccount'
                   : type === "FORMAL_PHOTO" 
                   ? 'formalPhoto'
                   : ""

      return documentData.findIndex((file: documentFile )=> file.type === type) >= 0 
        ? true
        : userFiles.findIndex((file: any ) => file.typeUpload === newType) >= 0 
        ? true
        : false;

  };

  const removeDocument = async (namesOfFilesToRemove: documentFile) => {
    setLoading(true)
    if(namesOfFilesToRemove && namesOfFilesToRemove?.id && namesOfFilesToRemove?.dmsFileId){
      const newCurrentFiles = await  deleteDocuments(namesOfFilesToRemove.dmsFileId ,UserId)
      if(newCurrentFiles){
        await dispatch(fetchDocumentByUser())
        setLoading(false)
      }
    }
  };

  return (
    <div>
    <Typography sx={{ mt: { xs: 3, sm: 6 }, mb: 3 }}>{t("text_file_Upload")}</Typography>

     <form name="formUpload" id="formUpload"  onSubmit={handleImageUpload}>
        <Grid container spacing={3}>
            <Grid item lg={3} sm={6}> 
              <div className={`custom-file-upload ${ handleDisabledBtn("ID_CARD")? 'disabled': ''}`} >
              <CloudUploadIcon className="upload-icon" />
              {t("text_types_file_idcard")}
              <input 
                type="file" 
                id="idCard"
                className="file-input"
                {...register("idCard")} 
                accept="image/png, image/jpeg, .pdf, .doc,.docx, .xls, .xlsx"
                onChange={(event) => { handleFileSelection(event, "idCard")}} />
              </div>

              <div className='title-file-upload'/>
              
              <div className={`custom-file-upload ${ handleDisabledBtn("HOUSE_REGISTRATION") ? 'disabled': ''}`} >
                <CloudUploadIcon className="upload-icon" />
                  {t("text_types_file_houseRegistration")}
                <input 
                   type="file" 
                    id="houseRegistration"
                    className="file-input"
                    {...register("houseRegistration")} 
                    accept="image/png, image/jpeg, .pdf, .doc,.docx, .xls, .xlsx"
                    onChange={(event) => { handleFileSelection(event, "houseRegistration")}} />
              </div>
            </Grid>

            <Grid item lg={3} sm={6}>
            {/* <div className={`custom-file-upload`} > */}
              <div className={`custom-file-upload ${ handleDisabledBtn("BANK_ACCOUNT")  ? 'disabled': ''}`} >
              <CloudUploadIcon className="upload-icon" />
              {t("text_types_file_bankAccount")}
              <input
                type="file" 
                {...register("bankAccount")}  
                id="bankAccount"
                className="file-input" 
                accept="image/png, image/jpeg, .pdf, .doc,.docx, .xls, .xlsx"
                onChange={(event) => { handleFileSelection(event,"bankAccount")}} />
              </div>

              <div className='title-file-upload'/>
              {/* <div className={`custom-file-upload`} > */}
              <div className={`custom-file-upload ${ handleDisabledBtn("FORMAL_PHOTO")   ? 'disabled': ''}`} >
                <CloudUploadIcon className="upload-icon" />
                {t("text_types_file_formalPhoto")}
                  <input
                    type="file" 
                    {...register("formalPhoto")}  
                    id="formalPhoto"
                    className="file-input" 
                    accept="image/png, image/jpeg, .pdf, .doc,.docx, .xls, .xlsx"
                    onChange={(event) => { handleFileSelection(event,"formalPhoto")}} />
                </div>
          </Grid>

          <Grid item lg={6} sm={12}>  
              <MultipleFileUpload  >
                  {userFiles.map((file:any,index:number) => (
                  <div key={index}>
                    <MultipleFileUploadStatusItem
                      className='file-upload-show'
                      file={file}
                      fileName={`${t(`${handleStatusFile(file.typeUpload)}`)} : ${file.name}`}
                      key={file.name} 
                      onClearClick={() => removeFiles([file.name])}
                      onReadSuccess={handleReadSuccess}
                      onReadFail={handleReadFail}
                      fileIcon ={<UploadFileIcon color="secondary"/>}
                    />
                  </div>
                    ))}
              </MultipleFileUpload>
              
    
              <MultipleFileUpload  >
                  {documentData.map((file:any,index:number) => (
                  <div key={index}>
                    <MultipleFileUploadStatusItem
                      className='file-upload-item'
                      file={file}
                      fileName={`${t(`${handleStatusFile(file.type)}`)} : ${file.name}`}
                      key={file.name} 
                      onClearClick={() => removeDocument(file)}
                      onReadSuccess={handleReadSuccess}
                      onReadFail={handleReadFail}
                      progressValue={100}
                      fileIcon ={<UploadFileIcon color="secondary"/>}
                    />
                  </div>
                    ))}
              </MultipleFileUpload>
              {loading &&(
              <Stack alignItems="center">
                  <CircularProgress />
              </Stack>
              )}
          </Grid>
    </Grid>
    <div className="container-form-upload">
            <button className='button-form-upload' type="submit">{t("text_files_Upload")}</button>
    </div>

    
        </form>
    </div>
  );
};

export default FileUploadStatus;
